<template>
  <form class="inner-step" @input="autoSave" @change="autoSave">
    <div class="container" v-if="booking_details && booked_game">
      <div class="row mt-2 d-flex justify-content-center">
        <div class="col-sm-12 col-md-10">
          <div class="row">
            <div class="col-md-6 mb-4">
              <div class="form-field">
                <label for="first-name"
                  >{{ $t('Form.Fields.FirstName') }}:</label
                >
                <input
                  id="first-name"
                  :class="[
                    'form-control',
                    {
                      'is-invalid':
                        $v.booking_customer.customer.first_name.$error,
                    },
                  ]"
                  v-model.trim="$v.booking_customer.customer.first_name.$model"
                />
                <div
                  class="msg"
                  v-if="!$v.booking_customer.customer.first_name.required"
                >
                  {{ $t('Form.Validation.Required') }}
                </div>
                <div
                  class="msg"
                  v-else-if="!$v.booking_customer.customer.first_name.latin"
                >
                  {{ $t('Form.Validation.Latin') }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="form-field">
                <label for="second-name"
                  >{{ $t('Form.Fields.SecondName') }}:</label
                >
                <input
                  id="second-name"
                  :class="[
                    'form-control',
                    {
                      'is-invalid':
                        $v.booking_customer.customer.second_name.$error,
                    },
                  ]"
                  v-model.trim="$v.booking_customer.customer.second_name.$model"
                />
                <div
                  class="msg"
                  v-if="!$v.booking_customer.customer.second_name.required"
                >
                  {{ $t('Form.Validation.Required') }}
                </div>
                <div
                  class="msg"
                  v-else-if="!$v.booking_customer.customer.second_name.latin"
                >
                  {{ $t('Form.Validation.Latin') }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="form-field">
                <label for="email">{{ $t('Form.Fields.Email') }}:</label>
                <input
                  id="email"
                  type="email"
                  :class="[
                    'form-control',
                    { 'is-invalid': $v.booking_customer.customer.email.$error },
                  ]"
                  v-model.trim="$v.booking_customer.customer.email.$model"
                />
                <div
                  class="msg"
                  v-if="!$v.booking_customer.customer.email.required"
                >
                  {{ $t('Form.Validation.Required') }}
                </div>
                <div
                  class="msg"
                  v-else-if="!$v.booking_customer.customer.email.email"
                >
                  {{ $t('Form.Validation.Required') }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div class="form-field">
                <label for="phone">{{ $t('Form.Fields.Phone') }}:</label>
                <input
                  id="phone"
                  type="tel"
                  :class="[
                    'form-control',
                    { 'is-invalid': $v.booking_customer.customer.phone.$error },
                  ]"
                  v-model.trim="$v.booking_customer.customer.phone.$model"
                />
                <div
                  class="msg"
                  v-if="!$v.booking_customer.customer.phone.required"
                >
                  {{ $t('Form.Validation.Required') }}
                </div>
                <div
                  class="msg"
                  v-else-if="!$v.booking_customer.customer.phone.numeric"
                >
                  {{ $t('Form.Validation.EnterAValidPhoneNumber') }}
                </div>
                <div
                  class="msg"
                  v-else-if="!$v.booking_customer.customer.phone.maxLength"
                >
                  {{
                    $t('Form.Validation.PhoneMaxLength', {
                      max:
                        $v.booking_customer.customer.phone.$params.maxLength
                          .max,
                    })
                  }}
                </div>
              </div>
            </div>
            <div class="col-12 mb-4">
              <div class="row d-flex align-items-end">
                <div class="col-6">
                  <div class="form-field">
                    <label for="discount"
                      >{{ $t('Form.Fields.Discount') }}:</label
                    >
                    <input
                      id="discount"
                      class="form-control"
                      v-model="discount.discount_code"
                      :readonly="discount.is_active"
                      ref="discount_code"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <button
                    v-if="discount.is_active"
                    @click="changeDiscount"
                    type="button"
                    class="btn is-accent is-small btn-code"
                    v-text="$t('Form.Change')"
                  />
                  <button
                    v-else
                    @click="handlerDiscount($event)"
                    type="button"
                    class="btn is-danger is-small btn-code"
                    v-text="$t('Form.Active')"
                  />
                </div>
              </div>
            </div>
            <div
              class="col-12 mb-4"
              v-if="Math.trunc(booking_amount.amount) !== 0"
            >
              <div class="form-field">
                <label for="payment-method">{{
                  $t('Form.Fields.ChoosePaymentMethod')
                }}</label>
                <select
                  id="payment-method"
                  class="form-control"
                  v-model="booking_customer.paymentType"
                >
                  <option value="" disabled selected hidden>
                    {{ $t('Choose') }}
                  </option>
                  <option value="card">
                    {{ $t('Form.Fields.CardPayment') }}
                  </option>
                  <option value="faktura">
                    {{ $t('Form.Fields.Invoice') }}
                  </option>
                </select>
              </div>
            </div>
            <template
              v-if="
                booking_customer.paymentType === 'faktura' &&
                booking_amount.amount !== 0
              "
            >
              <div class="col-md-6 mb-4">
                <div class="form-field">
                  <label for="org-nr"
                    >{{ $t('Form.Fields.OrganisationNumber') }}:</label
                  >
                  <input
                    id="org-nr"
                    :class="[
                      'form-control',
                      {
                        'is-invalid':
                          $v.booking_customer.billing.organization.org_nr
                            .$error,
                      },
                    ]"
                    v-model.trim="
                      $v.booking_customer.billing.organization.org_nr.$model
                    "
                  />
                  <div
                    class="msg"
                    v-if="
                      !$v.booking_customer.billing.organization.org_nr.required
                    "
                  >
                    {{ $t('Form.Validation.Required') }}
                  </div>
                  <div
                    class="msg"
                    v-else-if="
                      !$v.booking_customer.billing.organization.org_nr
                        .validNumber
                    "
                  >
                    {{ $t('Form.Validation.EnterValidFormat') }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-field">
                  <label for="org-name"
                    >{{ $t('Form.Fields.CompanyName') }}:</label
                  >
                  <input
                    id="org-name"
                    :class="[
                      'form-control',
                      {
                        'is-invalid':
                          $v.booking_customer.billing.organization.org_name
                            .$error,
                      },
                    ]"
                    v-model.trim="
                      $v.booking_customer.billing.organization.org_name.$model
                    "
                    @input="filterOrgName"
                  />
                  <div
                    class="msg"
                    v-if="
                      !$v.booking_customer.billing.organization.org_name
                        .required
                    "
                  >
                    {{ $t('Form.Validation.Required') }}
                  </div>
                  <div
                    class="msg"
                    v-else-if="
                      !$v.booking_customer.billing.organization.org_name.latin
                    "
                  >
                    {{ $t('Form.Validation.Latin') }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-field">
                  <label for="invoice-email"
                    >{{ $t('Form.Fields.InvoiceEmail') }}:</label
                  >
                  <input
                    id="invoice-email"
                    :class="[
                      'form-control',
                      {
                        'is-invalid':
                          $v.booking_customer.billing.organization.invoice_email
                            .$error,
                      },
                    ]"
                    v-model.trim="
                      $v.booking_customer.billing.organization.invoice_email
                        .$model
                    "
                  />
                  <div
                    class="msg"
                    v-if="
                      !$v.booking_customer.billing.organization.invoice_email
                        .required
                    "
                  >
                    {{ $t('Form.Validation.Required') }}
                  </div>
                  <div
                    class="msg"
                    v-else-if="
                      !$v.booking_customer.billing.organization.invoice_email
                        .email
                    "
                  >
                    {{ $t('Form.Validation.Email') }}
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4">
                <div class="form-field">
                  <label for="street-address"
                    >{{ $t('Form.Fields.Address') }}:</label
                  >
                  <input
                    id="street-address"
                    :class="[
                      'form-control',
                      {
                        'is-invalid':
                          $v.booking_customer.billing.address.street_address
                            .$error,
                      },
                    ]"
                    v-model.trim="
                      $v.booking_customer.billing.address.street_address.$model
                    "
                  />
                  <div
                    class="msg"
                    v-if="
                      !$v.booking_customer.billing.address.street_address
                        .required
                    "
                  >
                    {{ $t('Form.Validation.Required') }}
                  </div>
                  <div
                    class="msg"
                    v-else-if="
                      !$v.booking_customer.billing.address.street_address.latin
                    "
                  >
                    {{ $t('Form.Validation.Latin') }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-field">
                  <label for="zip-code"
                    >{{ $t('Form.Fields.PostCode') }}:</label
                  >
                  <input
                    id="zip-code"
                    :class="[
                      'form-control',
                      {
                        'is-invalid':
                          $v.booking_customer.billing.address.zip_code.$error,
                      },
                    ]"
                    v-model.trim="
                      $v.booking_customer.billing.address.zip_code.$model
                    "
                  />
                  <div
                    class="msg"
                    v-if="!$v.booking_customer.billing.address.zip_code.numeric"
                  >
                    {{ $t('Form.Validation.EnterValidNumbers') }}
                  </div>
                  <div
                    class="msg"
                    v-else-if="
                      !$v.booking_customer.billing.address.zip_code.required
                    "
                  >
                    {{ $t('Form.Validation.Required') }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-field">
                  <label for="city">{{ $t('Form.Fields.City') }}:</label>
                  <input
                    id="city"
                    :class="[
                      'form-control',
                      {
                        'is-invalid':
                          $v.booking_customer.billing.address.city.$error,
                      },
                    ]"
                    v-model.trim="
                      $v.booking_customer.billing.address.city.$model
                    "
                  />
                  <div
                    class="msg"
                    v-if="!$v.booking_customer.billing.address.city.required"
                  >
                    {{ $t('Form.Validation.Required') }}
                  </div>
                  <div
                    class="msg"
                    v-else-if="!$v.booking_customer.billing.address.city.latin"
                  >
                    {{ $t('Form.Validation.Latin') }}
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4">
                <div class="form-field">
                  <label for="contact-person"
                    >{{ $t('Form.Fields.ContactPerson') }}:</label
                  >
                  <input
                    id="contact-person"
                    :class="[
                      'form-control',
                      {
                        'is-invalid':
                          $v.booking_customer.billing.organization
                            .contact_person.$error,
                      },
                    ]"
                    v-model.trim="
                      $v.booking_customer.billing.organization.contact_person
                        .$model
                    "
                  />
                  <div
                    class="msg"
                    v-if="
                      !$v.booking_customer.billing.organization.contact_person
                        .required
                    "
                  >
                    {{ $t('Form.Validation.Required') }}
                  </div>
                  <div
                    class="msg"
                    v-else-if="
                      !$v.booking_customer.billing.organization.contact_person
                        .latin
                    "
                  >
                    {{ $t('Form.Validation.Latin') }}
                  </div>
                </div>
              </div>
              <div class="col-12 mb-4">
                <div class="form-field">
                  <label for="other-information"
                    >{{ $t('Form.Fields.OtherInformation') }}:</label
                  >
                  <input
                    id="other-information"
                    class="form-control"
                    v-model.trim="booking_customer.billing.comments"
                  />
                </div>
              </div>
            </template>
            <div class="col-12 mt-3 mb-3">
              <hr />
            </div>
            <div class="col-12 mb-2">
              <div class="form-field">
                <input
                  type="checkbox"
                  id="agree"
                  class="form-selector"
                  v-model="booking_customer.agree"
                />
                <label class="form-selector-label pl-5 paragraph-2" for="agree">
                  {{ $t('Form.Fields.IAmInterestedInReceivingInformation') }}
                </label>
              </div>
            </div>
            <div class="col-12 mb-3">
              <div class="box is-info">
                <h4>{{ $t('Form.Fields.BookingInformation') }}:</h4>
                <table>
                  <tr>
                    <td>{{ $t('GameType') }}:</td>
                    <td>{{ booking_details.type }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('Game') }}:</td>
                    <td>{{ booking_details.title }}</td>
                  </tr>
                  <tr
                    v-if="
                      booked_game.meeting_point &&
                      booked_game.meeting_point_text
                    "
                  >
                    <td>{{ $t('MeetingPoint') }}:</td>
                    <td>
                      <a :href="mapLink" target="_blank">
                        {{ booked_game.meeting_point_text }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Language') }}:</td>
                    <td>{{ booking_details.language | fullLangName }}</td>
                  </tr>
                  <tr v-if="booking_details.date && booking_details.time">
                    <td>{{ $t('Date') }}:</td>
                    <td>
                      {{ booking_details.date | date($i18n.locale) }} -
                      {{ booking_details.time }}
                    </td>
                  </tr>
                  <tr v-else>
                    <td>{{ $t('Date') }}:</td>
                    <td>{{ $t('AvailableImmediately') }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('NumberOfPeople') }}:</td>
                    <td>{{ booking_details.players }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('Teams') }}:</td>
                    <td>{{ booking_details.teams || 1 }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-12">
              <BookingPrice :booking_details="booking_details" />
            </div>
            <div class="col-12 mt-3 mb-3">
              <small>
                {{ $t('ByClickingTheConfirmButton') }}
                <a
                  :href="`https://www.ardi.one${ $t('link.terms') }`"
                  target="_blank"
                  >{{ $t('TermsOfService') }}</a
                >
                {{ $t('And') }}
                <a
                  :href="`https://www.ardi.one${ $t('link.privacy') }`"
                  target="_blank"
                  >{{ $t('PrivacyPolicy') }}</a
                >
                {{ $i18n.locale === 'ru' ? $t('Name') : '' }}
              </small>
            </div>
          </div>
          <div class="row d-flex justify-content-between">
            <div class="col-md-6 col-lg-5">
              <router-link :to="{ name: 'ChooseGame', query: $route.query }" class="btn is-accent">
                <i class="fas fa-angle-left"></i>
                {{ $t('Form.Back') }}
              </router-link>
            </div>
            <div class="mt-3 mt-md-0 col-md-6 col-lg-5">
              <button
                @click.prevent="confirm"
                class="btn is-accent"
                :disabled="isButtonDisabled"
              >
                {{ $t('Form.Confirm') }}
                <i class="fas fa-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import moment from 'moment';

import {
  email,
  required,
  maxLength,
  helpers,
} from 'vuelidate/lib/validators';
import { isEmpty, debounce } from 'lodash';
import BookingPrice from '@/components/BookingPrice';
import { mapGetters } from 'vuex';

export default {
  name: 'ConfirmBooking',
  components: {
    BookingPrice,
  },
  data() {
    return {
      formIsSaved: true,
      dataNeedSaving: true,
      booking_customer: {
        paymentType: '',
        customer: {
          name: '',
          first_name: '',
          second_name: '',
          email: '',
          phone: '',
        },
        billing: {
          organization: {
            org_nr: '',
            org_name: '',
            contact_person: '',
            invoice_email: '',
          },
          address: {
            street_address: '',
            city: '',
            zip_code: '',
            country: 'SE',
          },
          comments: '',
        },
        agree: false,
      },
    };
  },
  validations() {
    const latinRegex = /^[ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏàáâãäåæçèéêëìíîïÐÑÒÓÔÕÖØÙÚÛÜÝÞßðñòóôõöøùúûüýþÿa-zA-Z0-9][ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏàáâãäåæçèéêëìíîïÐÑÒÓÔÕÖØÙÚÛÜÝÞßðñòóôõöøùúûüýþÿ\- a-zA-Z0-9]*[ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏàáâãäåæçèéêëìíîïÐÑÒÓÔÕÖØÙÚÛÜÝÞßðñòóôõöøùúûüýþÿa-zA-Z0-9]$/;
    let rules = {
      booking_customer: {
        customer: {
          first_name: {
            required,
            latin: helpers.regex('latin', latinRegex),
          },
          second_name: {
            required,
            latin: helpers.regex('latin', latinRegex),
          },
          email: {
            required,
            email,
          },
          phone: {
            required,
            maxLength: maxLength(16),
          },
        },
      },
    };
    if (
      this.booking_customer.paymentType === 'faktura' &&
      Math.trunc(this.booking_amount.amount) !== 0
    ) {
      rules.booking_customer.billing = {
        organization: {
          org_nr: {
            required,
            validNumber(org_nr) {
              return (
                /^\d{6}(-){0,1}\d{4}$/.test(org_nr) &&
                (org_nr.length === 10 || org_nr.length === 11)
              );
            },
          },
          org_name: {
            required,
            latin: helpers.regex('latin', latinRegex),
          },
          contact_person: {
            required,
            latin: helpers.regex('latin', latinRegex),
          },
          invoice_email: {
            required,
            email,
          },
        },
        address: {
          street_address: {
            required,
            latin: helpers.regex('latin', latinRegex),
          },
          city: {
            required,
            latin: helpers.regex('latin', latinRegex),
          },
          zip_code: {
            required,
            latin: helpers.regex('latin', /^([0-9]{5,5}|[0-9]{3,3}[ ]{1,1}[0-9]{2,2})$/),
          },
        },
      };
    }
    return rules;
  },
  watch: {
    'booking_customer.customer.first_name'(val) {
      this.booking_customer.customer.name = `${val} ${this.booking_customer.customer.second_name}`;
    },
    'booking_customer.customer.second_name'(val) {
      this.booking_customer.customer.name = `${this.booking_customer.customer.first_name} ${val}`;
    },
  },
  computed: {
    ...mapGetters([
      'loading',
      'booked_game',
      'booking_details',
      'booking_amount',
      'discount',
    ]),
    isButtonDisabled() {
      return !!(
        this.$v.$invalid ||
        (this.booking_customer.paymentType === '' &&
          Math.trunc(this.booking_amount.amount) !== 0)
      );
    },
    mapLink() {
      if (
        this.booked_game.meeting_point &&
        this.booked_game.meeting_point_text
      ) {
        const [lat, lon] = this.booked_game.meeting_point.split(',');
        return `https://www.google.com/maps?q=${lat},${lon}`;
      }
      return null;
    },
  },
  methods: {
    handlerDiscount(e) {
      if (this.$refs['discount_code'].value === '') {
        return false;
      }
      const { target: button } = e;
      button.classList.add('is-load');
      button.disabled = true;
      const data_for_discount = {
        game_script_id: this.booking_details.id,
        num_players: parseInt(this.booking_details.players),
        num_teams: parseInt(this.booking_details.teams),
        discount_code: this.$refs['discount_code'].value,
      };
      this.$store
        .dispatch('fetch_discount', data_for_discount)
        .then(() => {
          this.$refs.discount_code.classList.remove('is-invalid');
        })
        .catch(() => {
          this.$refs.discount_code.classList.add('is-invalid');
          this.$toast.error(this.$t('PromoCodeWrong'));
        })
        .finally(() => {
          button.classList.remove('is-load');
          button.disabled = false;
        });
    },
    changeDiscount() {
      this.$store.dispatch('save_booking_amount', this.discount.amount);
      this.$store.dispatch('clear_discount');
    },
    track() {
      // this.$gtm.trackEvent({
      //   event: 'visit confirm page',
      // });
      // this.$gtm.trackEvent({
      //   event: 'begin_checkout',
      //   items: [
      //     {
      //       id: this.booking_details.id,
      //       brand: 'ARDI',
      //       list_position: 1,
      //       price: this.booking_amount.amount,
      //     },
      //   ],
      //   coupon: '',
      // });
    },
    trackBooking(event, booking_id, bookingParams) {
      const value = parseInt(bookingParams.booking.total_price.amount).toFixed(2);
      const tax = parseInt(bookingParams.booking.total_price.amount) === 0 ? 0 : (value * 0.2).toFixed(2)
      const eventData = {
        event,
        ecommerce: {
          transaction_id: booking_id,
          value,
          tax,
          shipping: '0.00',
          currency: bookingParams.booking.total_price.currency,
          items: [{
            item_id: bookingParams.booking.game_script_id,
            item_name: bookingParams.booking.title,
            item_brand: 'ARDI',
            item_category: bookingParams.booking.type,
            price: this.booked_game.price_per_unit.amount,
            quantity: this.booked_game.pricing_unit === 'player' ? bookingParams.booking.players : bookingParams.booking.teams,
          }]
        }
      };
      this.$gtm.trackEvent(eventData);
    },
    filterOrgName(event) {
      // let value = event.target.value;
      // value = value.replace(/^[A-zäöåÄÖÅ0-9][A-zäöåÄÖÅ0-9 ]+$/, '');
      // event.target.value = value;
      this.booking_customer.billing.organization.org_name = event.target.value.trim();
    },
    autoSave: debounce(function () {
      this.$store.dispatch('save_booking_customer', this.booking_customer);
    }, 250),
    confirm() {
      this.$gtm.trackEvent({
        event: 'checkout_progress',
      });
      this.$store.commit('set_loading', true);
      this.dataNeedSaving = false;
      let booking = {
        ...this.booking_details,
        total_price: this.booking_amount,
      };
      if (booking.date !== null) {
        booking.date = moment(booking.date).format('YYYY-MM-DD');
      }
      const bookingParams = {
        booking: {
          ...booking,
          game_script_id: booking.id,
          num_players: booking.players,
        },
        customer: this.booking_customer.customer,
        billing: this.booking_customer.billing,
      };

      if (this.discount.is_active) {
        bookingParams.booking.discount_code = this.discount.discount_code;
      }
      if (Math.trunc(bookingParams.booking.total_price.amount) === 0) {
        bookingParams.customer.paymentType = 'promocode';
      }
      if (booking.date !== null) {
        booking.date = moment(booking.date).format('YYYY-MM-DD');
      }

      // CARD PAYMENT
      if (
        this.booking_customer.paymentType === 'card' &&
        Math.trunc(this.booking_amount.amount) !== 0
      ) {
        const {
          location: { origin },
        } = window;
        const { href: thanksHref } = this.$router.resolve({
          name: 'BookingThanks',
        });
        const { href: failHref } = this.$router.resolve({
          name: 'BookingFailed',
        });

        bookingParams.success_url = `${origin}${thanksHref}/{CHECKOUT_SESSION_ID}?id=${this.$route.query.id}`;
        bookingParams.cancel_url = `${origin}${failHref}?id=${this.$route.query.id}`;
        this.$http
          .post('booking/payment/stripe', bookingParams)
          .then((response) => {
            this.trackBooking('purchase_card', response.data.booking_id, bookingParams);
            this.formIsSaved = true;
            let stripe = window.Stripe(response.data.stripe_public_key);
            stripe.redirectToCheckout({
              sessionId: response.data.checkout_session_id,
            });
          })
          .catch((error) => {
            console.error(error.response.data);
            this.$toast.error(
              'Could not book the game. Something went wrong. Our engineers are notified and will try to fix it as soon as possible. Feel free to contact our support: <a href="mailto:support@ardi.one">support@ardi.one</a>'
            );
          })
          .finally(() => {
            this.$store.commit('set_loading', false);
          });
      } else {
        // INVOICE PAYMENT AND FREE
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.$http
            .post("booking/book", bookingParams)
            .then((response) => {
              this.formIsSaved = true;
              this.trackBooking('purchase_invoice', response.data.booking_id, bookingParams);
              this.$router.push({
                name: 'BookingThanksInvoice',
                query: this.$route.query,
              }).then(() => {
                window.location.reload();
              });
            })
            .catch((error) => {
              console.error(error);
              this.$toast.error(
                'Could not book the game. Something went wrong. Our engineers are notified and will try to fix it as soon as possible. Feel free to contact our support: <a href="mailto:support@ardi.one">support@ardi.one</a>'
              );
            })
            .finally(() => {
              this.$store.commit('set_loading', false);
            });
        }
      }
    },
  },
  mounted() {
    if (isEmpty(this.booking_details)) {
      this.$router.push({ name: 'ChooseGame', query: this.$route.query });
    } else {
      this.$emit('onChangeStep', 2);
      window.onbeforeunload = () => {
        if (this.dataNeedSaving) {
          return 'Are you sure you want to leave?';
        }
        return;
      };
      if (!isEmpty(this.$store.getters.booking_customer)) {
        this.booking_customer = { ...this.$store.getters.booking_customer };
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import '../styles/utilities/utilities';

.price {
  font-size: 2em;
  font-weight: 700;

  &-bordered {
    border-bottom: 1px solid rgba(#fff, 0.3);
  }
}

.btn-code {
  height: 46.19px;
  line-height: 46.19px;
  padding: 1px 25px 0 25px;
  @include media(sm) {
    width: auto;
  }
}

table {
  width: 100%;

  td {
    padding: 5px;
  }
}

.inner-step {
  background-image: url('../assets/images/forms-3.png');
  background-repeat: no-repeat;
  background-position: calc(100% - 100px) center;
  background-size: 200px;
  @include media(md) {
    background-size: 320px;
  }
}
</style>
